import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BLOCKS } from "@contentful/rich-text-types"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const C = styled.div`
  margin: 20px 20%;
  h1 {
    text-align: center;
    margin-bottom: 60px;
  }
`

const Privacy = ({ data: { allContentfulPrivacy } }) => {
  return (
    <Layout>
      <SEO title="Privacy Statement for Sammons Industrial" />
      <C className="page-container">
        <div>
          <h1>{allContentfulPrivacy.edges[0].node.pageHeader}</h1>
          {renderRichText(allContentfulPrivacy.edges[0].node.pageContent, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            },
          })}
        </div>
      </C>
    </Layout>
  )
}

export default Privacy
export const pageQuery = graphql`
  {
    allContentfulPrivacy {
      edges {
        node {
          pageHeader
          pageContent {
            raw
          }
        }
      }
    }
  }
`
